import React, { useEffect, useRef, useState } from "react"
import classNames from 'classnames';
import { CardElement, PaymentRequestButtonElement } from 'react-stripe-elements';

import {
  ANALYTICS,
  ANNUAL_COST_USD,
  CURRENCY,
  LOCALE,
  STRIPE_PLAN_SENDER_PAYS,
  STRIPE_MESSAGE_QTY_MIN,
  STRIPE_MESSAGE_QTY_MAX,
  UNIT_COST_USD
} from '~common/constants'

import { trackCustomEvent } from '~common/helpers'
import useDebounce from '~common/use-debounce.hook';

import api from '~common/api';

import { useStripe } from '~components/stripe-element/stripe-element.component'
import Button from '~components/button/button.component';
import Icon from '~components/icon/icon.component';

import Notification from '~images/artwork/notification.svg';

import { createOptions } from '~styles/mixins.styles'
import useStyles from './payment-form.styles';

const PaymentForm = props => {
  const classes = useStyles(props);

  const { estimatedMessages, onError, onSuccess, organizationId, paymentAmount, paymentCountry, paymentCurrency, paymentLabel, selectedPlan } = props;

  const stripe = useStripe()[0];

  /**
   * Are we currently running a request against the API?
   */
  const [loading, setLoading] = useState(false);

  /**
   * We've either succeeded or encountered an error when hitting the API.
   */
  const [status, setStatus] = useState(null);

  /**
   * The number of messages to purchase (Plan 1 only)
   */
  const [messages, setMessages] = useState(estimatedMessages);

  /**
   * The environment allows us to make a payment
   */
  const [canMakePayment, setCanMakePayment] = useState(false);

  /**
   * Store the reference to Stripe
   */
  const paymentRequest = useRef(null);

  /**
   * Coupon code (Plan 1 only)
   */
  const [coupon, setCoupon] = useState('');
  const debouncedCoupon = useDebounce(coupon, 500)

  /**
   * Store valid coupon percent off from face value.
   */
  const [couponPercentOff, setCouponPercentOff] = useState()
  const [couponValid, setCouponValid] = useState()

  /** 
   * Coupon code validation in progress
   */
  const [couponLoading, setCouponLoading] = useState(false)

  // Validate coupon
  useEffect(() => {
    const fetchData = async () => {
      setCouponLoading(true)
      setCouponValid(null)
      
      try {
        const data = await api.validateCoupon({ coupon: debouncedCoupon })
        if (data.status_code === 200) {
          setCouponPercentOff(data.percent_off)
          setCouponValid(true)
        } else if (data.status_code === 400) {
          setCouponPercentOff(data.message)
          setCouponValid(false)
        }
      } catch (err) {
        setCouponPercentOff(null)
      } finally {
        setCouponLoading(false)
      }
    }

    if (debouncedCoupon.length >= 3 && debouncedCoupon.length <= 15) {
      fetchData()
    } else {
      setCouponLoading(false)
      setCouponPercentOff(null)
    }
    
  }, [debouncedCoupon])

  useEffect(() => {
    // Create payment buttons if available
    if (stripe && paymentRequest.current === null) {
      // For full documentation of the available paymentRequest options, see:
      // https://stripe.com/docs/stripe.js#the-payment-request-object
      paymentRequest.current = stripe.paymentRequest({
        country: paymentCountry,
        currency: paymentCurrency,
        total: {
          label: paymentLabel,
          amount: paymentAmount,
        },
      });

      // Send token retrieved from one-click pay button to API
      paymentRequest.current.on('token', async ({ complete, token, ...data }) => {
        try {
          await api.purchaseMessages({
            a: organizationId,
            sms: selectedPlan === STRIPE_PLAN_SENDER_PAYS ? messages : 0, // Send `0` if the Recipient Pays Plan is selected
            token: token.id,
            ...(couponPercentOff && couponValid && { coupon })
          })
          complete('success');
          onSuccess();
          trackCustomEvent({
            category: ANALYTICS.CATEGORY.PAYMENT_FORM,
            action: ANALYTICS.ACTION.PAYMENT_FORM_CHECKOUT_COMPLETE,
            label: organizationId,
            value: STRIPE_PLAN_SENDER_PAYS ? messages : 0
          })
        } catch (err) {
          complete('failure');
          onError();
          trackCustomEvent({
            category: ANALYTICS.CATEGORY.PAYMENT_FORM,
            action: ANALYTICS.ACTION.PAYMENT_FORM_CHECKOUT_ERROR,
            label: organizationId,
            value: err
          })
        }
      });

      // Check for one-click checkout support
      paymentRequest.current.canMakePayment().then((result) => {
        setCanMakePayment(!!result);
      });
    }
  }, [coupon, couponPercentOff, couponValid, estimatedMessages, messages, onError, onSuccess, organizationId, paymentAmount, paymentCountry, paymentCurrency, paymentLabel, selectedPlan, stripe])

  // Retrieve tokenized card information then provide our API with response token.
  const onSubmitSenderPays = event => {
    event.preventDefault();

    if (loading) return;

    setLoading(true)

    if (stripe) {
      stripe.createToken()
        .then(async res => {
          if (res.token) {
            const data = await api.purchaseMessages({
              a: props.organizationId,
              sms: props.selectedPlan === STRIPE_PLAN_SENDER_PAYS ? messages : 0, // Send `0` if the Recipient Pays Plan is selected
              token: res.token.id,
              ...(couponPercentOff && couponValid && { coupon })
            })
            if (data && data.status_code === 200) {
              setLoading(false)
              setStatus({
                success: true,
                message: <><p>You've successfully selected a plan!</p><p>Your account has <b>{data.credits}</b> messages available.</p><p><a href={`https://www.rainedout.net/admin/login.php?a=${props.organizationHash}`}>Log in to start sending messages</a></p></>
              })
              trackCustomEvent({
                category: ANALYTICS.CATEGORY.PAYMENT_FORM,
                action: ANALYTICS.ACTION.PAYMENT_FORM_CHECKOUT_COMPLETE,
                label: organizationId,
                value: STRIPE_PLAN_SENDER_PAYS ? messages : 0
              })
            } else {
              setLoading(false)
              setStatus({
                success: false,
                message: <><p>We were unable to process your payment.</p><p><a href="/" onClick={event => { event.preventDefault(); setStatus(null) }}>Click here to try again</a></p></>
              })
              trackCustomEvent({
                category: ANALYTICS.CATEGORY.PAYMENT_FORM,
                action: ANALYTICS.ACTION.PAYMENT_FORM_CHECKOUT_ERROR,
                label: organizationId
              })
            }
          } else {
            setLoading(false)
            setStatus({
              success: false,
              message: <><p>We were unable to process your payment.</p><p><a href="/" onClick={event => { event.preventDefault(); setStatus(null) }}>Click here to try again</a></p></>
            })
            trackCustomEvent({
              category: ANALYTICS.CATEGORY.PAYMENT_FORM,
              action: ANALYTICS.ACTION.PAYMENT_FORM_CHECKOUT_ERROR,
              label: organizationId
            })
          }
        });
    } else {
      setLoading(false)
      console.log("Stripe.js hasn't loaded yet.");
    }
  }

    // Retrieve tokenized card information then provide our API with response token.
    const onSubmitRecipientPays = async event => {
      event.preventDefault();
  
      if (loading) return;
  
      setLoading(true)

      const data = await api.purchaseMessages({
        a: props.organizationId,
        sms: -1
      })

      if (data && data.status_code === 200) {
        setLoading(false)
        setStatus({
          success: true,
          message: <><p>You've successfully selected a plan!</p><p><a href={`https://www.rainedout.net/admin/login.php?a=${props.organizationHash}`}>Log out then log back in to start sending messages</a></p></>
        })
        trackCustomEvent({
          category: ANALYTICS.CATEGORY.PAYMENT_FORM,
          action: ANALYTICS.ACTION.PAYMENT_FORM_CHECKOUT_COMPLETE,
          label: organizationId,
          value: STRIPE_PLAN_SENDER_PAYS ? messages : 0
        })
      } else {
        setLoading(false)
        setStatus({
          success: false,
          message: <><p>We were unable to process your payment.</p><p><a href="/" onClick={event => { event.preventDefault(); setStatus(null) }}>Click here to try again</a></p></>
        })
        trackCustomEvent({
          category: ANALYTICS.CATEGORY.PAYMENT_FORM,
          action: ANALYTICS.ACTION.PAYMENT_FORM_CHECKOUT_ERROR,
          label: organizationId
        })
      }
    }
  
  const renderTotalCost = () => (
    !couponLoading && couponPercentOff && couponValid ? 
    <>
      <span className={classes.totalOriginalCost}>
        {new Intl.NumberFormat(LOCALE, { style: 'currency', currency: CURRENCY }).format(messages * UNIT_COST_USD)}
      </span>
      <span className={classes.totalDiscountedCost}>{new Intl.NumberFormat(LOCALE, { style: 'currency', currency: CURRENCY }).format(messages * UNIT_COST_USD * ((100 - couponPercentOff) / 100))}</span>
    </> :
    new Intl.NumberFormat(LOCALE, { style: 'currency', currency: CURRENCY }).format(messages * UNIT_COST_USD)
  )

  const renderHelperText = () => (
    !couponLoading && couponPercentOff && couponValid ? <div className={classes.helperTextSuccess}>Coupon valid for {couponPercentOff}% off</div> : coupon && !couponLoading && couponValid === false ? <div className={classes.helperTextError}>Invalid coupon code</div> : couponLoading ? <div className={classes.helperText}>Checking coupon ...</div>: null
  )

  const renderPlanOne = () => (
    status ?
    renderStatus() :
    <form className={classes.card} onSubmit={onSubmitSenderPays}>
      <p className={classes.notification}><img src={Notification} alt="Notification icon" className={classes.notificationIcon} />There is a minimum purchase of {STRIPE_MESSAGE_QTY_MIN} messages.</p>
      <fieldset>
        <label htmlFor="messages" className={classes.label}>Message{messages === '1' ? '' : 's'}</label>
        <input
          className={classes.input}
          id="messages"
          type="number"
          min={STRIPE_MESSAGE_QTY_MIN}
          max={STRIPE_MESSAGE_QTY_MAX}
          value={messages}
          onChange={event => setMessages(event.currentTarget.value)}
          onBlur={event => setMessages(Math.max(STRIPE_MESSAGE_QTY_MIN, event.currentTarget.value))}
          placeholder="Qty"
          tabIndex={0}
        />
      </fieldset>
      <fieldset>
        <label htmlFor="coupon" className={classes.label}>Coupon</label>
        <input
          autocomplete="off"
          className={classes.input}
          id="coupon"
          type="text"
          minLength="4"
          maxLength="15"
          value={coupon}
          onChange={event => setCoupon(event.currentTarget.value.toUpperCase())}
          onBlur={event => setCoupon(event.currentTarget.value.toUpperCase())}
          placeholder="Enter code"
          tabIndex={0}
        />
        {renderHelperText()}
    </fieldset>
    <fieldset className={classes.totalCost}>
      <label className={classes.label}>Total Cost: {renderTotalCost()}</label>
    </fieldset>
    
    {canMakePayment ? (
      <fieldset>
        {/* <Divider> */}
          One-Click Payment
        {/* </Divider> */}
        <PaymentRequestButtonElement
          paymentRequest={paymentRequest.current}
          style={{
            height: 64,
            padding: 0,
  
            // For more details on how to style the Payment Request Button, see:
            // https://stripe.com/docs/elements/payment-request-button#styling-the-element
            paymentRequestButton: {
              theme: 'dark',
              height: '64px',
            },
          }}
        />
      </fieldset>
    ) : null}

    <fieldset>
      <div className={classes.label}>Credit Card</div>
      <div className={classes.cardInput}>
        <CardElement {...createOptions({})} />
      </div>
    </fieldset>
    {loading ? 
    <Icon name="loader-puff" size={24} className={classes.buttonLoading} /> :
    <Button inputSubmit={true} size="small" text="Submit Order" />}
  </form>
  )

  const renderPlanTwo = () => (
    (loading || status) ?
    renderStatus() :
    <form className={classes.card} onSubmit={onSubmitRecipientPays}>
      <p>Let the recipient choose and get email for free or they can upgrade to text.  Anyone that picks email will get all their messages for free.   Text is {new Intl.NumberFormat(LOCALE, { style: 'currency', currency: CURRENCY }).format(ANNUAL_COST_USD)} per year billed annually.</p>
      <p>When you confirm that you are choosing Recipient Choice, then we will contact everyone receiving texts from you and notify them of the need to make a choice.</p>
      <p>Plan selections are locked in for one year from your selection date.</p>
    <Button inputSubmit={true} size="small" text="Select 'Recipient Pays'" />
  </form>
  )

  const renderStatus = () => (
    status ? 
    <div className={classes.status}>
      {status.message}
    </div>: 
    <div className={classes.loading}>
      <Icon name="loader-puff" size={60} />
    </div>
  )

  return (
    <div
      className={classNames(
        [classes.paymentForm],
        {
          [props.className]: !!props.className
        }
      )}
    >
      {props.selectedPlan === STRIPE_PLAN_SENDER_PAYS ? renderPlanOne() : renderPlanTwo()}
    </div>
  );
}



PaymentForm.defaultProps = {
  paymentCountry: 'US',
  paymentCurrency: 'usd',
  paymentAmount: 0,
  // Stripe throws an error if this field is blank, "Empty total label may be confusing the user"
  // An extra space tricks their validation.  We need not worry about passing an empty label
  // as the field is populated eventually.
  paymentLabel: ' '
}

export default PaymentForm
