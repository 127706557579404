import { trackCustomEvent as trackGoogleEvent } from 'gatsby-plugin-google-analytics';

// Add commas to number strings
export const numberWithCommas = value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

// Check if objects are empty
export const isObjectEmpty = object => {
  if (typeof object !== 'object') return;

  for(var key in object) {
      if(object.hasOwnProperty(key))
          return false;
  }
  return true;
}

// Wrapper for tracking custom events
export const trackCustomEvent = ({ category, action, label = '', value = 0 }) => {
  if (process.env.GATSBY_ANALYTICS === 'true') {
    // Track with GA
    trackGoogleEvent({ category, action, label, value: parseInt(value) });

    // Track with Fullstory
    window.FS.event(`${category} — ${action}`, {
      label
    });
  } else {
    console.log(`Developer Analytic Log: ${category} — ${action} ${label.length ? '— ' + label : null}`);
  }
}
