
const API_ROOT = process.env.GATSBY_API_ROOT;

export default class API {

  /**
   * Check coupon validity
   * @param {*} data {"coupon":"SOMETHING21"}
   */
  static validateCoupon(data) {
    return fetch(`${API_ROOT}/coupon`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
    .then(res => res.json())
    .catch(err => err.json());
  }

  /**
   * Purchase SMS messages
   * @param {*} data {"a":"13","sms":"25", "token":"tok_xyz123", "coupon":"SOMETHING20"}
   */
  static purchaseMessages(data) {
    return fetch(`${API_ROOT}/purchase`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
    .then(res => res.json())
    .catch(err => err.json());
  }
}
