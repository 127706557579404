import {createUseStyles} from 'react-jss'

import {
  borderSeparator,
  input,
  label,
  link
} from '~styles/mixins.styles'

import {
  BREAKPOINTS,
  COLORS
} from '~styles/vars.styles'

export default createUseStyles({
  paymentForm: {},

  status: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: [20, 0],
    textAlign: 'center',
    '& p': {
      marginBottom: 0
    },
    '& a': {
      ...link({})
    },
    [`@media (min-width: ${BREAKPOINTS.PAYMENT_FORM_BIG}px)`]: {
      padding: [60, 0],
    }
  },

  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 60,
    color: COLORS.BLUE_01
  },

  buttonLoading: {
    color: COLORS.BLUE_01
  },

  notification: {
    display: 'flex',
    ...borderSeparator('top'),
    ...borderSeparator('bottom')
  },

  notificationIcon: {
    display: 'inline-block',
    marginRight: 15
  },

  label: {
    ...label
  },

  input: {
    ...input
  },

  helperText: {
    color: COLORS.BLUE_01,
    marginTop: 3,
    fontSize: 12
  },

  helperTextSuccess: {
    extend: 'helperText',
    color: COLORS.GREEN_01,
  },

  helperTextError: {
    extend: 'helperText',
    color: COLORS.RED_01
  },
  
  cardInput: {
    ...input
  },

  totalCost: {
    ...borderSeparator('bottom')
  },

  totalOriginalCost: {
    color: COLORS.BLUE_MUTED_03,
    marginRight: 5,
    textDecoration: 'line-through'
  },

  totalDiscountedCost: {
    color: COLORS.GREEN_01
  }
})